import iconCalendar from './icon--calendar.svg'
import iconChevronRight from './icon--chevron-right.svg'
import iconDownload from './icon--download.svg'
import iconFacebook from './icon--facebook.svg'
import iconHamburger from './icon--hamburger.svg'
import iconHeadphones from './icon--headphones.svg'
import iconLines from './icon--lines.svg'
import iconLink from './icon--link.svg'
import iconLinkedin from './icon--linkedin.svg'
import iconMagnifyingGlass from './icon--magnifying-glass.svg'
import iconMail from './icon--mail.svg'
import iconPhone from './icon--phone.svg'
import iconProfile from './icon--profile.svg'
import iconSpotify from './icon--spotify.svg'
import iconTwitter from './icon--twitter.svg'
import iconBluesky from './icon--bluesky.svg'
import iconYoutube from './icon--youtube.svg'
import iconBike from 'public/svg/icon--bike.svg'
import iconCheck from 'public/svg/icon--check.svg'
import iconCurrent from 'public/svg/icon--current.svg'
import iconFlag from 'public/svg/icon--flag.svg'
import iconGroup from 'public/svg/icon--group.svg'
import iconOpenArm from 'public/svg/icon--open-arm.svg'
import iconPaint from 'public/svg/icon--paint.svg'
import iconService from 'public/svg/icon--service.svg'
import iconVirus from 'public/svg/icon--virus.svg'

const icons = {
  'icon--calendar': iconCalendar,
  'icon--chevron-right': iconChevronRight,
  'icon--download': iconDownload,
  'icon--facebook': iconFacebook,
  'icon--hamburger': iconHamburger,
  'icon--headphones': iconHeadphones,
  'icon--lines': iconLines,
  'icon--link': iconLink,
  'icon--linkedin': iconLinkedin,
  'icon--magnifying-glass': iconMagnifyingGlass,
  'icon--phone': iconPhone,
  'icon--mail': iconMail,
  'icon--profile': iconProfile,
  'icon--spotify': iconSpotify,
  'icon--twitter': iconTwitter,
  'icon--bluesky': iconBluesky,
  'icon--youtube': iconYoutube,
  'icon--bike': iconBike,
  'icon--check': iconCheck,
  'icon--current': iconCurrent,
  'icon--flag': iconFlag,
  'icon--group': iconGroup,
  'icon--open-arm': iconOpenArm,
  'icon--paint': iconPaint,
  'icon--service': iconService,
  'icon--virus': iconVirus,
}

export default icons
